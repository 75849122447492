.back-button-container {
  display: flex;
  align-items: center;
  cursor: pointer;
  color: #33cccc;
  font-size: 16px;
  position: absolute;
  top: 50px;
  left: 20px;
  z-index: 1000;
}
.icon {
  margin-right: 3px;
}
.title {
  display: flex;
  align-items: center;
  font-size: 18px;
}