@import 'assets/scss/_themes-vars.module.scss';

.drawer-medugo-logo {
    width: 150px; 
    height: auto;
    @media (min-width: 768px) {
        width: 170px; 
        margin-top: 30px;
        margin-left: 80px;
        height: auto;
    }
}

.drawer-header {
    display: flex;
    justify-content: space-between;
    padding: 16px; 
    @media (min-width: 768px) {
        display: flex;
        flex-direction: row;
        justify-content:flex-start;
        margin-left: 10%;
        margin-top: 4%;
    }
}

.drawer-title {
    margin-top: 2px; 
    font-size: 24px;
    font-weight: 600;
    color:#49C1C3;
    font: 'Montserrat';
    @media (min-width: 768px) {
        font-size: 26px;
        color: $grey50;
    }
}

.drawer-content {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 16px; 
    margin-top: 0px;
    @media (min-width: 768px) {
        gap: 30px; 
        margin-top: 40px;
    }
}

.person-box {
    position: relative; 
}

.person-circle {
    height: 100px;
    width: 100px;
    border-radius: 50%;
    background-color:  $grey50;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 8px;
    overflow: hidden;
    border: 2px solid transparent;
    @media (min-width: 768px) {
        height: 84px;
        width: 84px;
    }
}

.person-image {
    width: 100%; 
    height: 100%;
    object-fit: cover; 
    border-radius: 50%; 
}


.person-circle.selected {
    border: 4px solid $primaryMain;
}

.tick-logo {
    position: absolute;
    top: 10px;
    right: 10px; 
    height: 24px;
    width: 24px;
}

.person-name {
    text-align: center;
    font: 'Montserrat';
    font-size: 18px;
    font-weight: 600;
    color:  $grey50;
    @media (min-width: 768px) {
        font-size: 16px;
    }
}

.drawer-button-container {
    margin-top: 36px; 
    padding: 16px;
    margin-bottom: 32px; 
    @media (min-width: 768px){
        width:36%;
        align-self: center;
        margin-top: 56px; 
    }
    
}
